import { combineReducers } from "redux";
import rolesReducer from "./roles";
import staffGroupsReducer from "./staffGroups";
import outletsReducer from "./outlets";
import complaincesReducer from "./compliances";
import usersReducer from "./users";
import teamsReducer from "./teams";
import foodItemsreducer from "./foodItems";
import organisationReducer from "./organisation";
import assetsReducer from "./assets";
import contractorsReducer from "./contractors";
import toiletsReducer from "./toilets";
import fireChecksReducer from "./fireChecks";
import zonesReducer from "./zones";
import contractorsSuppliersReducer from "./contractorsSuppliers";
import lineManagerReducer from "./lineManager";
import generalManagerDashboardReducer from "./generalManagerDashboard";
import checklistsReducer from "./checklists";
import docketsReducer from "./dockets";
import businessHourReducer from "./businessHours";
import tasksReducer from "./tasks";
import serviceLineReducer from "./serviceLine";
import drillDownReducer from "./drillDown";
import shiftReducer from "./shifts";
import foodItemsConfigReducer from "./foodItemsconfigurations";
import auditsReducer from "./audits";

export default combineReducers({
  rolesReducer,
  staffGroupsReducer,
  outletsReducer,
  complaincesReducer,
  usersReducer,
  teamsReducer,
  foodItemsreducer,
  organisationReducer,
  assetsReducer,
  contractorsReducer,
  toiletsReducer,
  fireChecksReducer,
  zonesReducer,
  contractorsSuppliersReducer,
  lineManagerReducer,
  generalManagerDashboardReducer,
  checklistsReducer,
  docketsReducer,
  businessHourReducer,
  tasksReducer,
  serviceLineReducer,
  drillDownReducer,
  shiftReducer,
  foodItemsConfigReducer,
  auditsReducer,
});
