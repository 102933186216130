// DEV URL
const devURL = `https://gxbrxjvk3h.execute-api.eu-west-1.amazonaws.com/dev/`;

// UAT
const uatURL = `https://5z5ulljasi.execute-api.eu-west-1.amazonaws.com/uat/`;

// PROD URL
const prodURL = `https://l0xuz8ve59.execute-api.eu-west-1.amazonaws.com/prod/`;

const ENV = process.env?.REACT_APP_ENV;
export let baseURL = "";
export let uiVersion = "";

if (ENV === "development") {
  baseURL = devURL;
  uiVersion = "dev-2";
} else if (ENV === "uat") {
  baseURL = uatURL;
  uiVersion = "uat-12";
} else if (ENV === "production") {
  baseURL = prodURL;
  uiVersion = "prod-15";
}

export const MASTER_PERMISSION = 2048;
